import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function BotChat({ message }) {
  const navigate = useNavigate();

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      {message.user === "bot" ? (
        <li className="text-left">
          {message.page === "products-service" ? (
            <Link onClick={(e) => myNavigation(e, "product-service")}>
              {message.value}
            </Link>
          )
           : message.page === "support" ? (
            <p>
              Please send an email to:{" "}
              <a href="mailto:info@eltaajir.com">support@eltaajir.com </a> with
              your inquiry and we will get back to you as soon as possible
            </p>
          ) : message.page === "other" ? (
            <p>
              Please send an email to:{" "}
              <a href="mailto:info@eltaajir.com">info@eltaajir.com</a> with your
              inquiry and we will get back to you as soon as possible
            </p>
          ) : message.page === "about-me" ? (
            <Link onClick={(e) => myNavigation(e, "about-me")}>
              {message.value}
            </Link>
          ) : (
            ""
          )}
        </li>
      ) : (
        <li className="text-right">{message.value}</li>
      )}
    </>
  );
}
