import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";

const AuthGuard = ({ component }) => {
  const navigate = useNavigate();
  const havetoken = sessionStorage.getItem("tm-token");
  const { search } = useLocation();
  const urlParam = new URLSearchParams(search);

  useEffect(() => {
    if (!havetoken) {
      let param_append = "";
      urlParam.forEach((value, key) => {
        param_append += `${key}=${value}&`;
      });
      if (param_append) {
        navigate(`/login?${param_append}`);
      } else {
        navigate(`/login`);
      }
    }
  }, []);

  return <React.Fragment>{havetoken ? component : <Loader />}</React.Fragment>;
};

export default AuthGuard;
