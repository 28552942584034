import React from "react";

const LinkdinIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.1625C0 3.20625 3.2875 0 7.34375 0H92.6562C96.7125 0 100 3.20625 100 7.1625V92.8375C100 96.7938 96.7125 100 92.6562 100H7.34375C3.2875 100 0 96.7938 0 92.8375V7.1625ZM30.8937 83.7125V38.5563H15.8875V83.7125H30.8937ZM23.3938 32.3875C28.625 32.3875 31.8812 28.925 31.8812 24.5875C31.7875 20.1563 28.6313 16.7875 23.4938 16.7875C18.3563 16.7875 15 20.1625 15 24.5875C15 28.925 18.2563 32.3875 23.2937 32.3875H23.3938ZM54.0688 83.7125V58.4938C54.0688 57.1438 54.1687 55.7937 54.5687 54.8312C55.65 52.1375 58.1188 49.3437 62.2688 49.3437C67.7 49.3437 69.8687 53.4813 69.8687 59.5563V83.7125H84.875V57.8125C84.875 43.9375 77.475 37.4875 67.6 37.4875C59.6375 37.4875 56.0688 41.8625 54.0688 44.9437V45.1H53.9688C54.0019 45.0478 54.0353 44.9957 54.0688 44.9437V38.5563H39.0687C39.2562 42.7938 39.0687 83.7125 39.0687 83.7125H54.0688Z"
        fill="#dcdcdc"
      />
    </svg>
  );
};
export default LinkdinIcon;
