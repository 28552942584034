import { useEffect, useState } from "react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import EducationEltjjar from "./pages/MerchantLead";
import PageNotFound from "./pages/PageNotFound";

import MainHeader from "./components/headers/MainHeader";
import MainFooter from "./components/footers/MainFooter";
import Header from "./components/headers/Header";
import Footer from "./components/footers/Footer";
import MyNotesList from "./pages/Blog/MyNotes";
import Telegram from "./pages/Telegram";
import LoginPage from "./pages/login/LoginPage";
import Signup from "./pages/SignupPage";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import InnerBlogPage from "./pages/Blog/InnerBlogPage";
import OTPPage from "./pages/login/OtpPage";
import PrivateTelegram from "./pages/PrivateTelegram";
import TradeView from "./pages/TradeView";
import RiskCalculator from "./pages/RiskCalculator";
import ForgotPassword from "./pages/ForgotPassword";
import ConfirmPassword from "./pages/ConfirmPassword";
import SuccessPage from "./pages/payment/SuccessPage";
import ProfilePage from "./pages/ProfilePage";
import SupportPage from "./pages/SupportPage";
import MyPlan from "./pages/MyPlan/MyPlan";
import CreateReview from "./pages/CreateReview";
import ProductAndServicePage from "./pages/ProductAndServicePage";
import HomePage from "./pages/Home/HomePage";
import Aboutme from "./pages/About/AboutMe";
import BrokerPage from "./pages/Broker/BrokerPage";
import FcaRegulator from "./pages/FcaRegulator";
import PaymentPage from "./pages/payment/PaymentPage";
import VideoList from "./pages/VideoList";
import Webinar from "./pages/Webinar";
import PDFRead from "./pages/PDFRead";
import AuthGuard from "./appGuard/authGuard";
import UnauthGuard from "./appGuard/unauthGuard";
import Fetcher from "./library/Fetcher";
import CookieConsent from "./pages/CookieConsent";
import { useDispatch, useSelector } from "react-redux";
import { handleUser, handlePlan } from "./reducers/userSlice";
import { useMutation } from "@tanstack/react-query";
import { homeInfo } from "./reducers/homeSlice";
import { pricingList } from "./api/pricingApi";
import { Chatbot } from "./components/chatbot/Chatbot";
import { useCookies } from "react-cookie";
import { handleActivePlanTab, handleNextPlanPayments, handlePlanPayments } from "./reducers/paymentSlice";
import { profileCheck } from "./lib/utils";
import StripePayment from "./pages/payment/StripePayment";
import PaymentProcessGuard from "./appGuard/paymentProcessGuard";

const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

function App() {
  let timer;
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);

  const user = useSelector((state) => state.authUser).user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navOptions, setNavOptions] = useState({ pr_telegram: false });
  const [cookies] = useCookies(["cookieConsent"]);

  useEffect(() => {
    pricingListMutation.mutate();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    let havetoken = "";
    if ((urlParam.get("token") || urlParam.get("status")) && location.pathname === "/") {
      havetoken = urlParam.get("token");
      sessionStorage.setItem("tm-token", havetoken);
      Fetcher.axiosSetup.defaults.headers.common.Authorization = `Bearer ${havetoken}`;
    } else {
      havetoken = sessionStorage.getItem("tm-token");
    }

    if (!havetoken) {
      dispatch(handleUser(null));
      dispatch(handlePlan([]));
      dispatch(handlePlanPayments([]));
      dispatch(handleNextPlanPayments([]));
    } else {
      if (!user) {
        getProfile.mutate();
      }

      Object.values(events).forEach((item) => {
        window.addEventListener(item, eventFunction);
      });
    }
  }, [location.pathname]);

  const getProfile = useMutation(() => Fetcher.get(`/getUserData`), {
    onSuccess: (data) => {
      if (data) {
        const { plan, services } = data.data;
        dispatch(handleUser(data.data.user));
        dispatch(handlePlan([...plan, ...services]));
        if ((urlParam.get("token") || urlParam.get("status")) && location.pathname === "/") {
          const user_profile = profileCheck(data.data.user);
          if (!user_profile) {
            navigate("/profile");
          } else {
            navigate("/");
          }
        } else {
          const exten_details = localStorage.getItem("pay_ex_intel");
          if (exten_details) {
            const exten_details_json = JSON.parse(exten_details);
            if (exten_details_json?.plan) {
              localStorage.removeItem("pay_ex_intel");
              navigate(`/payment-processing?plan=${exten_details_json?.plan}&mode=payment&ln=en`);
            }
          }
        }
      }
      return;
    },
    onError: (error) => {
      if (error.request.status === 401 || error.request.status === 429) {
        sessionStorage.removeItem("tm-token");
        navigate("/login");
        dispatch(handleUser(null));
        dispatch(handlePlan([]));
        dispatch(handlePlanPayments([]));
        dispatch(handleNextPlanPayments([]));
        dispatch(handleActivePlanTab("first"));
      }
    },
  });

  const pricingListMutation = useMutation(pricingList, {
    onSuccess: (data) => {
      if (data?.data) {
        dispatch(homeInfo({ type: "planPricing", payload: data?.data?.data }));
      }
      return;
    },
  });

  const eventFunction = () => {
    resetTimer();
    handleLogoutTimer();
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      logoutAction();
    }, 3600 * 1000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const logoutAction = () => {
    sessionStorage.removeItem("tm-token");
    dispatch(handleUser(null));
    dispatch(handlePlan([]));
    dispatch(handlePlanPayments([]));
    dispatch(handleNextPlanPayments([]));

    Object.values(events).forEach((item) => {
      window.removeEventListener(item, eventFunction);
    });

    navigate("/login");
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`page-wrapper${location.pathname === "/lead" ? " merchant_varient" : ""}${
          location.pathname === "/otp" ||
          location.pathname === "/login" ||
          location.pathname === "/signup" ||
          location.pathname === "/forgot-password" ||
          location.pathname.startsWith("/confirm-password")
            ? " remove-btm-margin"
            : ""
        }`}
      >
        {/* <Notification /> */}
        {location.pathname === "/lead" ? <Header /> : <MainHeader navOptions={navOptions} setNavOptions={(event) => setNavOptions(event)} />}
        <section className="main-sec-wrapper">
          <Routes>
            <Route path="/otp" element={<UnauthGuard component={<OTPPage />} />} />
            <Route path="/login" element={<UnauthGuard component={<LoginPage />} />} />
            <Route path="/signup" element={<UnauthGuard component={<Signup />} />} />
            <Route path="/forgot-password" element={<UnauthGuard component={<ForgotPassword />} />} />
            <Route path="/confirm-password/:id/:email" element={<UnauthGuard component={<ConfirmPassword />} />} />
            <Route path="/brokers" element={<AuthGuard component={<BrokerPage />} />} />
            <Route path="/trading-view" element={<AuthGuard component={<TradeView />} />} />
            {navOptions.pr_telegram && <Route path="/private-telegram" element={<AuthGuard component={<PrivateTelegram />} />} />}
            <Route path="/risk-calculator" element={<AuthGuard component={<RiskCalculator />} />} />
            <Route path="/payment" element={<AuthGuard component={<PaymentPage />} />} />

            <Route path="/payment-successful" element={<AuthGuard component={<SuccessPage />} />} />
            <Route path="/payment-processing" element={<PaymentProcessGuard component={<StripePayment />} />} />
            <Route path="/profile" element={<AuthGuard component={<ProfilePage />} />} />
            <Route path="/create_review" element={<AuthGuard component={<CreateReview />} />} />
            <Route path="/myplan" element={<AuthGuard component={<MyPlan />} />} />
            <Route path="/watch" element={<AuthGuard component={<VideoList />} />} />
            <Route path="/read" element={<AuthGuard component={<PDFRead />} />} />

            <Route path="/" element={<HomePage />} />
            <Route path="/about-me" element={<Aboutme />} />
            <Route path="/my-notes" element={<MyNotesList />} />
            <Route path="/telegram-qr" element={<Telegram />} />
            <Route path="/lead" element={<EducationEltjjar />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
            <Route path="/fca-disclaimer" element={<FcaRegulator />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<InnerBlogPage />} />
            <Route path="/webinar" element={<Webinar />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/product-service" element={<ProductAndServicePage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </section>

        {location.pathname !== "/signup" &&
          location.pathname !== "/login" &&
          location.pathname !== "/otp" &&
          location.pathname !== "/forgot-password" &&
          !location.pathname.includes("confirm-password") &&
          (location.pathname === "/lead" ? <Footer /> : <MainFooter />)}

        <Chatbot />
        {!cookies.cookieConsent && <CookieConsent />}
      </div>
    </>
  );
}

export default App;
