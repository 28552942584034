import { Col, Container, Row } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { getAllTestimonial } from "../../api/testimonialApi";
import { homeInfo } from "../../reducers/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function Testimonials() {
  const testimonials = useSelector((state) => state.merchant.testimonial) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (testimonials.length === 0) {
      testiMonial.mutate();
    }
  }, []);

  const testiMonial = useMutation(getAllTestimonial, {
    onSuccess: (data) => {
      if (data?.data) {
        dispatch(homeInfo({ type: "testimonial", payload: data?.data?.data }));
      }
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const shortName = (text) => {
    if (text) {
      const max = 2;
      let new_string = "";
      text = text.split(" ");
      for (let i = 0; i < text.length; i++) {
        if (i + 1 <= max) {
          new_string = new_string + text[i][0];
        } else {
          break;
        }
      }
      new_string = new_string.toUpperCase();
      return new_string;
    }
    return "";
  };

  return (
    <section className="elt-testimonials">
      <Container>
        <h2>Testimonials</h2>
        <Row>
          {testimonials &&
            testimonials.map((tsm, index) => (
              <Col key={index} xs={12} md={6} lg={4} xl={3}>
                <div className="elt-testimonials-box">
                  <div
                    className="elt-testimonials-boxInner"
                    dangerouslySetInnerHTML={{
                      __html: tsm.review ? tsm.review : "",
                    }}
                  ></div>
                  <div className="elt-testimonials-ctn">
                    <div className="elt-testimonials-user">
                      {shortName(tsm.client_name ? tsm.client_name : "")}
                    </div>
                    <span>{tsm.client_name ? tsm.client_name : ""}</span>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}
