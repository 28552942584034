import React from "react";

import { Helmet } from "react-helmet";
import Banner from "./Banner";
import Courses from "./Courses";
import Benifit from "./Benifit";
import Testimonials from "../../components/testimonials/Testimonials";


export default function HomePage() {
  
  return (
    <>
      <Helmet>
        <title>The Merchant - Forex Trader & Mentor</title>
        <meta property="og:title" content="The Merchant - Forex Trader & Mentor" />        
        <link rel="canonical" href="https://eltaajir.com/" />
      </Helmet>
      <Banner />
      <Testimonials/>
      <Benifit/>
      <Courses/>
    </>
  );
}
