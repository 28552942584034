import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import FooterLogo from "../../assets/images/eltaajir-logo.png";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useNavigate } from "react-router-dom";
import Instagram from "../../assets/images/instagram.svg";
import Facebook from "../../assets/images/facebook.svg";
import Twitter from "../../assets/images/twitter.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Tiktok from "../../assets/images/tiktok.svg";
import Youtube from "../../assets/images/youtube.svg";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, Form, InputGroup } from "react-bootstrap";
import Fetcher from "../../library/Fetcher";

function MainFooter() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState("");

  const handleChangeInput = (event) => {
    let { value } = event.target;
    setEmail(value);
    setEmailValidation("");
  };

  const newsletterMutation = useMutation(
    (uploadedData) => Fetcher.post(`/newsLetterSubscription`, uploadedData),
    {
      onSuccess: (data) => {
        if (data?.data) {
          toast.success(data?.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
        return;
      },
      onError: (error) => {
        if (error?.response?.data?.errors) {
          return ;
        }
      },
    }
  );

  const onSubscribeHandler = (e) => {
    e.preventDefault();
    const isValidate = validate();
    if (isValidate === true) {
      newsletterMutation.mutate({ email });
      setEmail("");
    } else {
      setEmailValidation(isValidate);
    }
  };

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const validate = () => {
    var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email) {
      if (!pattern.test(email)) {
        return "Please enter valid email address";
      } else {
        return true;
      }
    } else {
      return "Please enter your email address";
    }
  };

  return (
    <>
      <footer className="site-footer-main position-relative">
        <Container className="subscrib-container">
          <div className="position-relative subscribe-us">
            <Row className="align-items-center position-relative">
              <Col xs={12}>
                <div className="subscribe-us-left">
                  <h4>Subscribe to receive</h4>
                  <h3>
                    Exclusive updates, free resources, and monthly discount
                    codes
                  </h3>
                  <p className="mb-0">
                    on our products and services. Stay ahead in Forex trading
                    with our latest news and e-books!
                  </p>
                </div>
              </Col>
              <Col xs={12}>
                <div className="subscribe-us-right">
                  <InputGroup className="email-input-box">
                    <Form.Control
                      value={email}
                      placeholder="Your email here"
                      aria-label="Your email here"
                      onChange={(e) => handleChangeInput(e)}
                      name="email"
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={(e) => onSubscribeHandler(e)}
                    >
                      SUBSCRIBE
                    </Button>
                  </InputGroup>
                  {emailValidation && (
                    <p className="set-posi validate_subsriber">{emailValidation}</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Container>
          <div className="footer-wrap-main">
            <Row className="justify-content-between">
              <Col className="footer-logo-col-main">
                <Link
                  className="footer-logo d-block"
                  onClick={(e) => myNavigation(e, "/")}
                >
                  <Image src={FooterLogo} />
                </Link>
                <div className="footer-social-wrap">
                  <p className="footer-logo-label-main">
                    Decoding The Forex Market
                  </p>
                </div>
              </Col>

              <Col className="footer-company-link-main">
                <div className="footer-links-main">
                  <Row className="justify-content-between">
                    <Col className=" footer-links-col">
                      <h4>Resources</h4>
                      <ListGroup as="ul">
                        <ListGroup as="li">
                          <Link
                            onClick={(e) =>
                              myNavigation(e, "/terms-and-conditions")
                            }
                          >
                            Terms & Conditions
                          </Link>
                        </ListGroup>
                        <ListGroup as="li">
                          <Link
                            onClick={(e) => myNavigation(e, "/privacy-policy")}
                          >
                            Privacy Policy
                          </Link>
                        </ListGroup>
                        <ListGroup as="li">
                          <Link
                            onClick={(e) => myNavigation(e, "/fca-disclaimer")}
                          >
                            Disclaimer
                          </Link>
                        </ListGroup>
                      </ListGroup>
                    </Col>
                    <Col className=" footer-links-col">
                      <h4>Resources</h4>
                      <ListGroup as="ul">
                        {/* <ListGroup as="li">
                          <Link onClick={(e) => myNavigation(e, "/community")}>
                            {t("community_link")}
                          </Link>
                        </ListGroup> */}
                        <ListGroup as="li">
                          <Link onClick={(e) => myNavigation(e, "/support")}>
                            Support
                          </Link>
                        </ListGroup>
                        {/* <ListGroup as="li">
                          <Link onClick={(e) => myNavigation(e, "/security")}>
                            {t("security")}
                          </Link>
                        </ListGroup> */}
                      </ListGroup>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col className="footer-logo-col-main">
                <div className="footer-social-wrap">
                  <h4>Follow Us on Social Media</h4>
                  <ListGroup
                    as="ul"
                    className="d-flex flex-row justify-content-between footer-social-list-main"
                  >
                    <ListGroup as="li">
                      <Link
                        to="https://www.instagram.com/eltaajir/"
                        target="_blank"
                      >
                        <Image
                          src={Instagram}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                    <ListGroup as="li">
                      <Link
                        to="https://www.facebook.com/profile.php?id=61551475581788"
                        target="_blank"
                      >
                        <Image
                          src={Facebook}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                    <ListGroup as="li">
                      <Link to="https://twitter.com/eltaajir" target="_blank">
                        <Image
                          src={Twitter}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                    <ListGroup as="li">
                      <Link
                        to="https://www.linkedin.com/company/91021665"
                        target="_blank"
                      >
                        <Image
                          src={Linkedin}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                    <ListGroup as="li">
                      <Link
                        to="https://www.tiktok.com/@eltaajir?lang=en"
                        target="_blank"
                      >
                        <Image
                          src={Tiktok}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                    <ListGroup as="li">
                      <Link
                        to="https://www.youtube.com/channel/UCX64WdWCUK9aesPlNjPLJbQ"
                        target="_blank"
                      >
                        <Image
                          src={Youtube}
                          className="footer-social-icon-main"
                        />
                      </Link>
                    </ListGroup>
                  </ListGroup>
                </div>
              </Col>
            </Row>
            <div className="copyright-sec">
              <p>
                © The Merchant Hesham Ahmed Elsaid (The Merchant) registered in
                Poland.
              </p>
              <p>TAX Number: 9571154770</p>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
}
export default MainFooter;
