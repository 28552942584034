import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/eltaajir-logo.png";
import userIcon from "../../assets/images/defaultUser.png";
import FacebookIcon from "../../assets/svg/Facebook";
import InstaIcon from "../../assets/svg/InstaIcon";
import YoutubeIcon from "../../assets/svg/YoutubeIcon";
import LinkdinIcon from "../../assets/svg/LinkdinIcon";
import Fetcher from "../../library/Fetcher";
import filePdf from "../../assets/images/merchant.xlsx";
import { logout, telegramAccess } from "../../api/authApi";
import { NavDropdown, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { backend_url } from "../../config/constants";
import { handlePlan, handleUser } from "../../reducers/userSlice";
import { profileCheck } from "../../lib/utils";
import {
  handleActivePlanTab,
  handleNextPlanPayments,
  handlePlanPayments
} from "../../reducers/paymentSlice";

function MainHeader(props) {
  const location = useLocation();
  const token = sessionStorage.getItem("tm-token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navOptions, setNavOptions } = props;
  const user = useSelector((state) => state.authUser).user;
  const [isprofile, setIsProfile] = useState(true);
  const [topbarShow, setTopbarShow] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleCollapse = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (token) {
      if (location.pathname === "/video-list" || location.pathname === "payment-processing") {
        setTopbarShow(false);
      }
      Fetcher.axiosSetup.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (user?.id) {
        telegramUserVerificationInit.mutate(user?.id);
      }
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      setIsProfile(() => profileCheck(user));
    }
  }, [user]);

  const myNavigation = (e, path) => {
    setExpanded(false);
    e.preventDefault();
    navigate(path);
  };

  const logoutMutation = useMutation(logout, {
    onSuccess: (data) => {
      if (data?.data) {
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        });
      }

      dispatch(handleUser(null));
      dispatch(handlePlan([]));
      dispatch(handlePlanPayments([]));
      dispatch(handleNextPlanPayments([]));
      dispatch(handleActivePlanTab("first"));
      sessionStorage.removeItem("tm-token");
      navigate("/login");
      return;
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        return;
      }
    }
  });

  const logoutHandler = () => {
    logoutMutation.mutate();
  };

  const telegramUserVerificationInit = useMutation(telegramAccess, {
    onSuccess: (data) => {
      setNavOptions({
        ...navOptions,
        pr_telegram: true
      });
    },
    onError: (error) => {
      if (error) {
        if (location.pathname === "/private-telegram") navigate("/");
      }
    }
  });

  return (
    <header className="site-header">
      {topbarShow && (
        <div className="header-topbar fca-tagline">
          <p>
            The Merchant – eltaajir is an educational platform, not a provider of financial services
            and products <Link onClick={(e) => myNavigation(e, "/fca-disclaimer")}>Learn more</Link>
            .
          </p>
        </div>
      )}
      {topbarShow && (
        <div className="header-topbar">
          <Container>
            <Stack direction="horizontal">
              <div className="header-email">
                <Link to="mailto:info@eltaajir.com">info@eltaajir.com</Link>
              </div>

              <div className="header-socialink">
                <ListGroup as="ul">
                  <ListGroup.Item as="li">
                    <Link
                      to="https://www.facebook.com/profile.php?id=61551475581788"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <Link to="https://www.instagram.com/eltaajir/" target="_blank">
                      <InstaIcon />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <Link
                      to="https://www.youtube.com/channel/UCX64WdWCUK9aesPlNjPLJbQ"
                      target="_blank"
                    >
                      <YoutubeIcon />
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <Link to="https://www.linkedin.com/company/91021665" target="_blank">
                      <LinkdinIcon />
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Stack>
          </Container>
        </div>
      )}

      {location.pathname !== "/signup" &&
        location.pathname !== "/login" &&
        location.pathname !== "/otp" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/payment-processing" &&
        !location.pathname.includes("confirm-password") && (
          <Navbar
            expand="lg"
            className="header-navbar-main"
            onToggle={handleCollapse}
            expanded={expanded}
          >
            <Container>
              <Navbar.Brand onClick={(e) => myNavigation(e, "/")}>
                <Image src={Logo} />
              </Navbar.Brand>
              <Navbar.Toggle
                onClick={() => setExpanded(expanded ? false : "expanded")}
                aria-controls="merchant-navbar-nav"
              />
              <Navbar.Offcanvas id="merchant-navbar-nav" className="mt-navbar" placement="start">
                <Offcanvas.Header closeButton className="p-0 mb-4">
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expanded}`}>
                    <Image src={Logo} />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Nav>
                  <Nav.Link onClick={(e) => myNavigation(e, "/")}>Home</Nav.Link>
                  <Nav.Link onClick={(e) => myNavigation(e, "/about-me")}>About Me</Nav.Link>
                  <Nav.Link className="pns" onClick={(e) => myNavigation(e, "/product-service")}>
                    Products and Services
                  </Nav.Link>
                  <Nav.Link onClick={(e) => myNavigation(e, "/my-notes")}>My Blog</Nav.Link>
                  <Nav.Link onClick={(e) => myNavigation(e, "/webinar")}>Webinars</Nav.Link>
                  {token && (
                    <>
                      <NavDropdown title="Tools" id="nav-dropdown">
                        <NavDropdown.Item onClick={(e) => myNavigation(e, "/risk-calculator")}>
                          Risk Calculator
                        </NavDropdown.Item>

                        <NavDropdown.Item onClick={(e) => myNavigation(e, "/trading-view")}>
                          Trading View
                        </NavDropdown.Item>

                        <NavDropdown.Item href={filePdf} download>
                          Trade Tracker
                        </NavDropdown.Item>

                        <NavDropdown.Item onClick={(e) => myNavigation(e, "/brokers")}>
                          Brokers
                        </NavDropdown.Item>

                        {navOptions.pr_telegram && (
                          <NavDropdown.Item onClick={(e) => myNavigation(e, "/private-telegram")}>
                            Telegram
                          </NavDropdown.Item>
                        )}

                        <NavDropdown.Item href="https://www.forexfactory.com/" target="_blank">
                          Economic News
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="https://www.babypips.com/tools/forex-market-hours"
                          target="_blank"
                        >
                          Market Hours
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  )}
                  {token ? (
                    <NavDropdown
                      title={
                        <>
                          {user && (
                            <span className="user-fullname">
                              {user?.first_name + " " + user?.last_name}
                            </span>
                          )}
                          <span className={`card-icon-wrap${!isprofile ? " active" : ""}`}>
                            <Image
                              src={user?.image ? `${user?.image}` : userIcon}
                              className="card-icon"
                            />
                          </span>
                        </>
                      }
                      className="user-prof-btn"
                      id="login-user"
                    >
                      <NavDropdown.Item
                        className="profile-links"
                        onClick={(e) => myNavigation(e, "/profile")}
                      >
                        My Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="profile-links"
                        onClick={(e) => myNavigation(e, "/myplan")}
                      >
                        My Plan
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="profile-links"
                        onClick={(e) => myNavigation(e, "/create_review")}
                      >
                        Give Review
                      </NavDropdown.Item>
                      <NavDropdown.Item className="profile-links" onClick={logoutHandler}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <Stack direction="horizontal" className="header-seacrh-lang-signup-main">
                      <div className="signup-btn-div-main">
                        <Link
                          className="btn btn-primary text-center login-btn"
                          onClick={(e) => myNavigation(e, "/login")}
                        >
                          Login
                        </Link>
                      </div>
                    </Stack>
                  )}
                </Nav>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        )}
    </header>
  );
}
export default MainHeader;
