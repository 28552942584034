import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Logo from "../../assets/images/logo_new.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { resendOtpApi, sendOtp } from "../../api/authApi";
import Fetcher from "../../library/Fetcher";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";
import { successAlert } from "../../lib/utils";

export default function OTP() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [subData, setSubData] = useState({
    email: "",
    otp: "",
  });
  const [validation, setValidation] = useState({
    email: "",
    otp: "",
  });

  useEffect(() => {
    let haveEmail = localStorage.getItem("tm-email");
    if (haveEmail) {
      setSubData({
        ...subData,
        email: haveEmail,
      });
      localStorage.removeItem("tm-email");
    } else {
      navigate("/login");
    }
  }, []);

  const submitOptMutation = useMutation(sendOtp, {
    onSuccess: (data) => {
      setLoading(false);

      if (data?.data) {
        successAlert(data?.data.message);
        const token = data?.data?.token;
        if (token) {
          sessionStorage.setItem("tm-token", data?.data?.token);
          localStorage.removeItem("tm-email");
          Fetcher.axiosSetup.defaults.headers.common.Authorization = `Bearer ${data?.data?.token}`;
          navigate("/");
        } else {
          navigate("/");
        }
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        toast.error(error.response.data.errors, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        return;
      }
    },
  });
  const resendOtpMutation = useMutation(resendOtpApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        successAlert(data?.data.message);
      }

      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const onSubmitOtp = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      submitOptMutation.mutate(subData);
    }
    return false;
  };

  const onResendOtpHandler = () => {
    setLoading(true);
    resendOtpMutation.mutate({ email: subData.email });
  };

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setSubData({
      ...subData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const validateForm = () => {
    const formKeys = Object.keys(subData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], subData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };
  const validate = (name, value) => {
    if (name === "otp") {
      if (!value) {
        return "Please enter OTP";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>The Merchant - OTP</title>
        <link rel="canonical" href="https://eltaajir.com/otp" />
        <meta property="og:title" content="Hesham Elsaid - OTP" />
      </Helmet>
      {loading && <Loader />}
      <section className="login-page">
        <div className="logoin-box-shadow">
          <Row className="p-0 m-0 align-items-center">
            <Col md={6} lg={8} className="p-0 m-0">
              <div className="login-img">
                <Image src={require("../../assets/images/add-logoin-img.png")} className="w-100" onClick={() => navigate("/")} />
              </div>
            </Col>
            <Col md={6} lg={4} className="p-0 m-0">
              <div className="login-form">
                <div className="logo-login">
                  <Image src={Logo} />
                </div>
                <p>Enter OTP (One Time Password)</p>
                <Form onSubmit={onSubmitOtp} className="log-in-input">
                  <Form.Group className="mb-3" controlId="formGroupOtp">
                    <Form.Label className="form-new-lable">OTP</Form.Label>
                    <Form.Control
                      className={`input-box ${validation.otp ? "error-add" : ""}`}
                      type="number"
                      placeholder="Enter OTP Code"
                      name="otp"
                      onChange={(e) => handleChangeInput(e)}
                    />
                    <span className="error-new">{validation.otp}</span>
                  </Form.Group>
                  <Button className="login-btn" variant="primary" type="submit" onClick={onSubmitOtp}>
                    Submit
                  </Button>
                </Form>

                <div className="separator"></div>
                <Button className="sign-up-btn" variant="primary" onClick={() => onResendOtpHandler()}>
                  Resend OTP Code
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
