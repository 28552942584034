import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import aboutImg from "../../assets/images/hesham-about.png";
import { Helmet } from "react-helmet";
import TopBanner from "../../components/banner/TopBanner";

export default function Aboutme() {
  const videoRef = useRef(null);
  
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadeddata = () => {
        videoRef.current.play();
      };
    }
  }, []);
  
  return (
    <div>
      <Helmet>
        <title>Hesham Elsaid - Forex & Crypto trader</title>
        <meta property="og:title" content="Hesham Elsaid - Forex & Crypto trader" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      <TopBanner title="About Me" />
      <section className="aboutMe-section">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="aboutMe-ctn-block">
                <Image src={aboutImg} fluid />
                <h2>About Hesham Elsaid</h2>
                <p>
                  Hello and welcome to my world of forex trading, where
                  expertise meets personal experience. I'm Hesham Elsaid, born
                  and raised in the vibrant city of Alexandria, Egypt. My
                  academic journey began in the field of Computer & Electronics,
                  leading me from a technician diploma to further studies in
                  Italy, and eventually, my new home in Europe.
                </p>
                <p>
                  My forex trading adventure started in 2012, alongside a
                  flourishing career at a forex broker and an impactful 8-year
                  tenure at Intel in Poland as a Business Development Manager.
                  Here, I honed my skills in managing significant international
                  accounts, overseeing responsibilities exceeding $1 billion.
                </p>
                <p>
                  However, it was the forex market that truly captivated me. My
                  initial years were challenging, marked by financial losses and
                  emotional trials. But, fueled by determination and an
                  unwavering quest for knowledge, I turned my fortunes around in
                  2019. This breakthrough led me to offer personalized 1:1
                  mentoring to aspiring traders in Poland and Italy. In 2023, I
                  took a leap forward, establishing my company in Poland to
                  empower English and Arabic speaking clients through education
                  and mentorship in forex trading.
                </p>
                <p>
                  Away from the charts, I'm passionate about sports. A former
                  professional in karate and soccer, I now find balance in
                  soccer, squash, running, and yoga. My life is enriched by the
                  support of family and friends, who are my bedrock.
                </p>

                <p>
                  Achieving financial freedom and escaping the 9-5 grind has
                  always been my dream. In 2023, I embarked on this mission
                  full-time, dedicating myself to the FX market. My goal is not
                  just to teach trading techniques but to inspire and guide you
                  in becoming a conscious, successful trader.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="aboutMe-section ctn-section">
        <Container>
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="aboutMe-ctn-block">
                <h2>What's My Mission?</h2>
                <p>
                  My mission is to be your guide and mentor in the forex market,
                  helping you to navigate its complexities with confidence.
                  Here's how I aim to empower you:
                </p>
                <p>
                  <strong>1. Guiding Your Market Journey:</strong> With over a
                  decade in forex trading, I offer insights and strategies to
                  navigate the market successfully.
                </p>
                <p>
                  <strong>2. Saving Your Time and Money:</strong> Avoid the
                  common pitfalls of early trading and maintain your faith in
                  the market and yourself.
                </p>
                <p>
                  <strong>3. Building Passive Income:</strong> Let's work
                  together towards creating a sustainable source of income in
                  the forex market.
                </p>
                <p>
                  <strong>4. Fostering Financial Freedom:</strong> Dream of
                  breaking free from financial constraints? I'm here to help you
                  make that a reality.
                </p>
                <p>
                  <strong>5. Safeguarding Against Inflation:</strong> Protect
                  your savings from inflation and maintain their value through
                  strategic forex trading.
                </p>
                <p>
                  <strong>6. Mentoring Successful Forex Trading:</strong>{" "}
                  Success in forex is a skill that can be learned. I share my
                  knowledge to help you become a proficient forex trader.
                </p>

                <p>
                  <strong>7. Community of Conscious Traders:</strong> Join a
                  community where knowledge and experiences are shared,
                  fostering collective growth.
                </p>
                <p>
                  <strong>8. Real Talk About Success:</strong> We focus on
                  sustainable growth in forex trading, moving beyond the
                  illusion of overnight riches.
                </p>
                <p>
                  Join me on this journey to master forex trading, where
                  knowledge, patience, and community form the pillars of
                  success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
