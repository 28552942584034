import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { addPaymentDetails } from "../../api/authApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import { useLocation } from "react-router-dom";
import { courseDetailApi } from "../../api/coursesApi";

function StripePayment() {
  const { search } = useLocation();
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const { user } = useSelector((state) => state.authUser);
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const plan_id = params.get("plan");
  const mode = params.get("mode");
  const ln = params.get("ln");

  useEffect(() => {
    if (plan_id) {
      const planfilter = planPricing.filter((val) => val.id == plan_id)[0];

      
      const paymentData = {
        package_id: plan_id,
        plan_id: planfilter.id,
        payment_method: 2,
        plan_lang: ln,
        county: user?.country,
        vat_price: 0,
        city: user?.city,
        package_price: planfilter?.amount ? planfilter?.amount : 0,
        total_price: planfilter?.amount ? planfilter?.amount : 0,
        discount_code: "",
        discount_price: 1,
        discount_apply_all_cycle: 0,
        is_term_accept: 1,
        is_confirm_payment_accept: 1
      };
      setLoading(true);
      if (planfilter && user) {
        paymentMutation.mutate(paymentData);
      } else {
        toast.error("get error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false
        });
        setLoading(false);
      }      
    }
  }, [plan_id, mode]);

  const courseDetailMutation = useMutation(courseDetailApi, {
    onSuccess: async (data) => {
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        return;
      }
    }
  });

  // const { selectplan } = useSelector((state) => state.payments);

  // useEffect(() => {
  //   const paymentData = {
  //     package_id: 1,
  //     plan_id: selectplan?.plan_id,
  //     payment_method: 2,
  //     plan_lang: selectplan?.language,
  //     county: user?.country,
  //     vat_price: 0,
  //     city: user?.city,
  //     package_price: selectplan?.price ? selectplan?.price : 0,
  //     total_price: selectplan?.price ? selectplan.price : 0,
  //     discount_code: "",
  //     discount_price: 1,
  //     discount_apply_all_cycle: 0,
  //     is_term_accept: 1,
  //     is_confirm_payment_accept: 1,
  //   };

  //   setLoading(true);
  //   if (selectplan && user) {
  //     paymentMutation.mutate(paymentData);
  //   } else {
  //     toast.error("get error", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: false,
  //     });
  //     setLoading(false);
  //   }
  // }, []);

  const paymentMutation = useMutation(addPaymentDetails, {
    onSuccess: (data) => {
      const pay_res_url = data.data.data.pay_response.url;
      if (pay_res_url) {
        window.location.replace(pay_res_url);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.errors, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  return <div>{loading && <Loader />}</div>;
}

export default StripePayment;
