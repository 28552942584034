import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { courseListApi } from "../../api/coursesApi";
import { useDispatch, useSelector } from "react-redux";
import { handleSelectPalan, selectPalan } from "../../reducers/paymentSlice";
import { useNavigate } from "react-router-dom";

export default function Courses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const [display, setDisplay] = useState([]); // Stores the courses to display
  const [loading, setLoading] = useState(false); // Tracks loading state
  const user = useSelector((state) => state.authUser).user;

  useEffect(() => {
    setLoading(true);
    AllCoursesMutation.mutate({
      page: 1,
      lang: "",
      is_free: 0,
      order: "desc"
    });
  }, [dispatch]);

  const AllCoursesMutation = useMutation(courseListApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        const firstEngCource = data.data.courses?.data?.filter((item) => item.language == "en");
        setDisplay(firstEngCource[0]);
      }
    },
    onError: () => {
      setLoading(false); // Stop loading on error      
    }
  });

  const handlepayment = (data) => {
    navigate(`/payment-processing?plan=${planPricing[0].id}&mode=extpay&mode=payment&ln=en`);
  };

  return (
    <>
      <section className="elt-courses-section">
        <div className="elt-courses-row">
          <div className="elt-courses-img">
            <img
              src={`https://www.eltaajir.com/admin/storage/app/public/${display?.course_image}`}
              alt="courses img"
            />
          </div>
          <div className="elt-courses-ctn">
            <h3>{display?.course_title}</h3>
            {/* <div dangerouslySetInnerHTML={{ __html: planPricing[0]?.description }}></div> */}

            <div className="elt-courses-price">
              <p className="elt-courses-price-text">
                €{planPricing[0]?.amount}
                {/* <span className="elt-courses-sale">Sale</span> */}
                <span className="elt-tax-line">Taxes included.</span>
              </p>
              <div className="elt-cart-btn-grp">
                <div onClick={() => handlepayment(display)} className="elt-cart-btn">
                  Buy Now
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
