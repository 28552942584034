import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.3063C100 22.5188 77.6125 -0.00624847 50 -0.00624847C22.375 1.52618e-06 -0.0125122 22.5188 -0.0125122 50.3125C-0.0125122 75.4188 18.275 96.2312 42.175 100.006V64.85H29.4875V50.3125H42.1875V39.2188C42.1875 26.6125 49.6562 19.65 61.075 19.65C66.55 19.65 72.2687 20.6313 72.2687 20.6313V33.0063H65.9625C59.7562 33.0063 57.8187 36.8875 57.8187 40.8688V50.3063H71.6812L69.4687 64.8438H57.8125V100C81.7125 96.225 100 75.4125 100 50.3063Z"
        fill="#dcdcdc"
      />
    </svg>
  );
};
export default FacebookIcon;
